import i18n from '@/imports/startup/client/i18n';

import { formatDate, formatMoney, formatTerms, getDetails } from './formatters';

const formatPdfDocument = (document) => {
  if (!document) return '';
  const { type, documentNumber } = document;
  return [i18n.t(`document.exports.schema.type.shortName.${type}`), documentNumber].join(' ');
};

const getText = (termsArray) => {
  return termsArray.map(({ text }) => text).join(' + ');
};

const createOrderItem = (orderTableItem, tenantName, supplierName) => {
  const { product, price, quantity, discount, difference } = orderTableItem;

  return {
    sku: product?.sku,
    product: product?.name,
    terms: formatTerms({ price, quantity, discount }),
    differences: getDetails(difference, { price, quantity, discount }).map(
      ({ type, templates: { billed, ordered } }) => {
        switch (type) {
          case 'amount':
            return {
              text: i18n.t('openIssuesModal.table.order.details.unbilled.amount', {
                billed: getText(billed),
                ordered: getText(ordered),
                supplier: supplierName,
                customer: tenantName,
              }),
            };
          case 'quantity':
            return {
              text: i18n.t('openIssuesModal.table.order.details.unbilled.quantity', {
                billed: getText(billed),
                ordered: getText(ordered),
                supplier: supplierName,
                customer: tenantName,
              }),
            };
          default:
            return null;
        }
      }
    ),
    totalDiff: formatMoney(difference.amount) || i18n.t('commons.unknown'),
  };
};

export const convertToPdfJson = (tenant, order) => {
  return {
    reportName: `request_${new Date().toISOString().split('T')[0]}`,
    to: order.supplier.name,
    from: tenant.name,
    document: formatPdfDocument(order.source?.document),
    items: order.products
      .filter((product) => !!product.difference)
      .map((product) => createOrderItem(product, tenant.name, order.supplier.name)),
    request_date: formatDate(new Date()),
    order_date: formatDate(order.date),
  };
};
