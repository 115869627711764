<template>
  <el-dialog
    visible
    append-to-body
    :show-close="false"
    width="30%"
    custom-class="order-item-create-modal rounded"
    @close="$emit('close')"
  >
    <template #title>
      <div class="p-4 bg-white rounded-top border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <h3>{{ translate(`titles.${formType}`) }}</h3>
          <Button
            type="text"
            :disabled="loading"
            class="p-0 text-typography-primary action-btn"
            @click="$emit('close')"
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    </template>
    <el-form ref="form" v-loading="loading" class="p-4 mt-2" :model="itemForm" size="mini">
      <el-form-item :label="labels.product.label" prop="productId" :rules="rules.product">
        <el-select
          v-model="itemForm.productId"
          filterable
          autocomplete="on"
          :placeholder="labels.product.placeholder"
          :style="{ width: '100%' }"
        >
          <el-option
            v-for="product in products"
            :key="product.id"
            :label="generateProductLabel(product)"
            :value="product.id"
          />
        </el-select>
      </el-form-item>
      <div class="w-50">
        <el-form-item :label="labels.quantity.label" prop="quantity" :rules="rules.quantity">
          <InputNumber v-model="itemForm.quantity" :placeholder="labels.quantity.placeholder" />
        </el-form-item>
        <el-form-item :label="labels.price.label" prop="price" :rules="rules.price">
          <InputMoneyShekels v-model="itemForm.price" :placeholder="labels.price.placeholder" />
        </el-form-item>
        <el-form-item :label="labels.discount.label" prop="discount" :rules="rules.discount">
          <InputNumber v-model="itemForm.discount" :placeholder="labels.discount.placeholder" />
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="pb-4 px-4">
        <Button :disabled="loading" type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button :disabled="loading" type="primary" :loading="loading" @click="handleConfirm">
          {{ $t('commons.apply') }}</Button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button, InputMoneyShekels, InputNumber } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { useOrderItemCreate } from '@/modules/order';

export default {
  components: { Button, CloseIcon, InputMoneyShekels, InputNumber },
  props: {
    orderId: { type: String, required: true },
    products: { type: Array, default: () => [] },
    activeTab: { type: Number, required: true },
  },
  setup() {
    const { createOrderItem } = useOrderItemCreate();
    return {
      createOrderItem,
    };
  },
  data() {
    return {
      itemForm: {},
      loading: false,
    };
  },
  computed: {
    rules() {
      return {
        product: [
          {
            required: true,
            message: this.translate('inputs.rules.product.empty'),
          },
        ],
        quantity: [
          {
            required: true,
            type: 'number',
            message: this.translate('inputs.rules.quantity.empty'),
          },
          {
            validator: (_, value, callback) => {
              if (value < 0) return callback(new Error(this.translate('inputs.rules.quantity.negative')));
              if (value === 0) return callback(new Error(this.translate('inputs.rules.quantity.zero')));
              callback();
            },
          },
        ],
        price: [
          {
            validator: (_, value, callback) => {
              if (value < 0) return callback(new Error(this.translate('inputs.rules.price.negative')));
              callback();
            },
          },
        ],
        discount: [
          {
            validator: (_, value, callback) => {
              if (value < 0) return callback(new Error(this.translate('inputs.rules.discount.negative')));
              callback();
            },
          },
        ],
      };
    },
    labels() {
      return {
        product: {
          label: this.$t('commons.product'),
          placeholder: this.$t('commons.selectProduct'),
        },
        quantity: {
          label: this.translate(`inputs.quantity.label.${this.formType}`),
          placeholder: this.translate('inputs.quantity.placeholder'),
        },
        price: {
          label: this.translate(`inputs.price.label.${this.formType}`),
          placeholder: this.translate('inputs.price.placeholder'),
        },
        discount: {
          label: this.translate(`inputs.discount.label.${this.formType}`),
          placeholder: this.translate('inputs.discount.placeholder'),
        },
      };
    },
    formType() {
      return this.activeTab === 1 ? 'returnNotice' : 'ordered';
    },
  },
  methods: {
    translate(key) {
      return this.$t(`eventMapModal.orderCard.orderItemCreateForm.${key}`);
    },
    generateProductLabel(product) {
      return product.sku ? `${product.sku} / ${product.name}` : product.name;
    },
    handleConfirm() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.loading = true;
          const mapActiveTabToSign = [1, -1];
          try {
            await this.createOrderItem({
              orderId: this.orderId,
              data: {
                ...this.itemForm,
                quantity: this.itemForm.quantity * mapActiveTabToSign[this.activeTab],
              },
            });
            this.$message.success(this.$t('commons.messages.action.success'));
            this.$emit('submit');
          } catch (error) {
            this.$message.error(this.$t('commons.messages.action.error'));
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

:deep(.order-item-create-modal) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
  input {
    text-align: right;
    [dir='ltr'] & {
      text-align: left;
    }
  }
}
</style>
