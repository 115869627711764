<template>
  <DifferenceModal
    v-if="!loading"
    event-type="order"
    :customer-order-item="product"
    :difference="difference"
    :diffs-to-show="Object.values(DIFFERENCE_PROPERTY)"
    :document="document"
    :supplier="order.supplier"
    :business="business"
    :date="new Date(order.date)"
    :supplier-order-item="supplierOrderItem"
    @close="$emit('close')"
    @apply="handlePriceDifferenceResolution"
  >
    <div class="buttons-container">
      <span class="header-button">
        <div class="header fw-bold">{{ tenantName }}</div>
        <el-form ref="decisionForm" :model="formModel" :show-message="false">
          <el-form-item prop="selectedPriceResolutionOption" :rules="{ required: true }">
            <el-select
              v-if="difference.price || difference.discount"
              v-model="selectedPriceResolutionOption"
              :placeholder="$t('eventMapModal.orderCard.differenceModal.decision.price.takeDecision')"
              :disabled="!product.id"
              class="mt-2 mb-2 w-100"
            >
              <el-option
                v-for="value in Object.values(PRICE_RESOLUTION_COMMAND)"
                :key="value"
                :value="value"
                :label="
                  $t(`eventMapModal.orderCard.differenceModal.decision.price.dropdown.${value}`, {
                    supplierCharge: supplierCharge,
                  })
                "
              />
            </el-select>
          </el-form-item>
        </el-form>
        <el-select
          v-if="difference.quantity"
          v-model="selectedQuantityResolutionOption"
          disabled
          class="mt-2 mb-2 w-100"
          :placeholder="$t('eventMapModal.orderCard.differenceModal.decision.quantity.takeDecision')"
        />
      </span>
    </div>
  </DifferenceModal>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { filter, pipe, not, isNil } from 'ramda';
import { useMutation, useQuery } from '@vue/apollo-composable';

import { openProductModal } from '@/modules/products/store/product-modal';
import { TERM_CREATE_MUTATION } from '@/modules/purchase-management/graphql_derpecated';
import { PRICING_METHODS_ENUM } from '@/modules/suppliers/models/term';
import { useOrderProductPatch } from '@/modules/order';
import { currency, percent } from '@/locale/numberConfig';

import DifferenceModal, { DIFFERENCE_PROPERTY } from '../../../commons/components/differenceModal';
import { ORDER_ITEM_QUERY } from '../../../compositions/order/graphql';

const filterNil = filter(pipe(isNil, not));

const PRICE_RESOLUTION_COMMAND = {
  APPROVE_ONCE: 'approveOnce',
  CREDIT_NEEDED: 'creditNeeded',
  APPROVE: 'approve',
  CHANGE_TERM: 'changeTerm',
};

export default {
  components: { DifferenceModal },
  props: {
    productIndex: { type: Number, required: true },
    order: { type: Object, required: true },
    afterSubmit: { type: Function, default: () => {} },
    business: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const formatMoneyShekels = (value) =>
      typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(root.$i18n.locale, currency)
        : null;
    const formatPercent = (value) =>
      typeof value === 'number' && !Number.isNaN(value)
        ? Number(value / 100).toLocaleString(root.$i18n.locale, percent)
        : null;

    const { patchOrderProduct } = useOrderProductPatch();
    const { mutate: createTerm } = useMutation(TERM_CREATE_MUTATION);

    const selectedPriceResolutionOption = ref(null);
    const selectedQuantityResolutionOption = ref(null);

    const difference = computed(
      () => props.order.differences.find((diff) => diff.productIndex === props.productIndex) ?? {}
    ); // after resolving the diff the modal updates with no diff - error
    const product = computed(() => props.order.products[props.productIndex] ?? {});

    const { result, loading } = useQuery(
      ORDER_ITEM_QUERY,
      () => ({
        orderId: difference.value.supplierOrderId,
        itemId: difference.value.supplierItemId,
      }),
      () => ({
        enabled: !isNil(difference.value.supplierItemId),
      })
    );

    const supplierOrderItem = computed(() => result.value?.orderItem);

    return {
      createTerm,
      patchOrderProduct,
      product,
      difference,
      supplierOrderItem,
      loading,
      supplierCharge: computed(() => {
        const price = formatMoneyShekels(
          difference.value.price ? difference.value.price.supplierValue : product.value.price
        );
        const discount = formatPercent(
          difference.value.discount ? difference.value.discount.supplierValue : product.value.discount
        );
        const strings = [price, discount].filter((value) => !isNil(value));

        return difference.value.price || difference.value.discount ? `(${strings.join(' + ')})` : null;
      }),
      DIFFERENCE_PROPERTY,
      PRICE_RESOLUTION_COMMAND,
      tenantName: computed(() => props.business.name),
      selectedPriceResolutionOption,
      selectedQuantityResolutionOption,
      document: computed(() => props.order.source?.document),
      formModel: computed(() => ({
        selectedPriceResolutionOption: selectedPriceResolutionOption.value,
        selectedQuantityResolutionOption: selectedQuantityResolutionOption.value,
      })),
    };
  },
  methods: {
    async handlePriceDifferenceResolution() {
      this.$refs.decisionForm.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading();

          try {
            switch (this.formModel.selectedPriceResolutionOption) {
              case PRICE_RESOLUTION_COMMAND.APPROVE_ONCE:
                await this.handlePriceDifferenceApproveOnce();
                break;
              case PRICE_RESOLUTION_COMMAND.CREDIT_NEEDED:
                break;
              case PRICE_RESOLUTION_COMMAND.APPROVE:
                await this.handlePriceDifferenceApprove();
                break;
              case PRICE_RESOLUTION_COMMAND.CHANGE_TERM:
                openProductModal(this.product.product.id);
                return;
              default:
                break;
            }

            await this.afterSubmit();
            this.$message.success(this.$i18n.t('commons.messages.action.success'));
            this.$emit('close');
          } catch (e) {
            console.error(e);
            this.$message.error(this.$i18n.t('commons.messages.action.error'));
          } finally {
            loading.close();
          }
        }
      });
    },
    async handlePriceDifferenceApproveOnce() {
      await this.patchOrderProduct({
        orderId: this.order.id,
        productIndex: this.productIndex,
        data: filterNil({
          price: this.difference.price?.supplierValue,
          discount: this.difference.discount?.supplierValue,
        }),
      });
    },
    async handlePriceDifferenceApprove() {
      const termCreateInput = {
        businessId: this.order.businessId,
        supplierId: this.order.supplier.id,
        fromDate: this.order.date,
        itemId: this.product.product.id,
      };

      if (this.difference.price) {
        await this.createTerm({
          termCreateInput: {
            ...termCreateInput,
            price: Math.round(this.difference.price.supplierValue * 100),
            pricingMethod: PRICING_METHODS_ENUM.FIXED_PRICE,
            type: 'pricing',
            priceIncludingTax: false,
          },
        });
      }
      if (this.difference.discount) {
        await this.createTerm({
          termCreateInput: {
            ...termCreateInput,
            rewardValue: this.difference.discount.supplierValue,
            type: 'promotion',
            rewardType: 'discount',
            rewardRealizationTime: 'onBill',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.buttons-container {
  margin-top: 2em;
  display: flex;
  align-items: center;

  .header-button {
    display: flex;
    flex-direction: column;

    [dir='rtl'] & {
      align-items: flex-start;
    }
    [dir='ltr'] & {
      align-items: flex-end;
    }
    width: 100%;

    .header {
      font-size: 0.87em;
      color: black;
      margin-bottom: 0.6em;
    }

    span {
      width: 100%;
      display: flex;
      align-items: center;

      @at-root [dir='rtl'] i {
        margin-right: 0.7em;
      }

      @at-root [dir='ltr'] i {
        margin-left: 0.7em;
      }
    }

    .el-button {
      height: 2.75em;
      width: 100%;

      &[disabled] {
        background-color: #ecf0f3;
        color: #c4c6cf;
        border-color: #ecf0f3;
      }
    }
  }

  .divider {
    margin: 0 1em;
    height: 9em;
  }
}
</style>
