<template>
  <el-dialog
    visible
    :title="$t(`eventMapModal.updateProductModule.${updateType}`)"
    append-to-body
    custom-class="change-modal"
    @close="$emit('close')"
  >
    <div class="general-info-container">
      <h3>
        {{ product.name || $t('commons.generalProduct') }}
      </h3>
      <div v-if="product.sku" class="general-info-container__sku">{{ $t('commons.sku') }}: {{ product.sku }}</div>
    </div>
    <div class="card">
      <div class="card-body">
        {{ $t(`eventMapModal.updateProductModule.inputs.currentValue.${updateType}`) }}
        <div class="fw-bold card-body__value__container card-body__second__child">
          {{ value }}
        </div>
      </div>
      <div class="card-body">
        <div>
          {{ $t(`eventMapModal.updateProductModule.inputs.newValue.${updateType}`) }}
        </div>
        <el-form ref="form" :model="inputForm" class="card-body__second__child">
          <el-form-item prop="newValue" :rules="inputRule">
            <el-input v-model.number="inputForm.newValue" class="change-modal__input" type="number" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table-title-container">
      <h4>{{ $t('eventMapModal.orderCard.orderUpdateProductModal.tableTitle') }}</h4>
    </div>
    <div class="table-container">
      <Table v-loading="loading" :columns="columns" :data="tableDataOrderItems" :hover="false" rounded border>
        <template #cell-quantity="{ rowData: { quantity } }">
          {{ formatNumber(quantity) }}
        </template>
        <template #cell-reference="{ rowData: document }">
          <a v-if="document" class="text-primary" role="button" @click.stop="handleOpenDocument(document.reference.id)">
            {{ $t(`document.exports.schema.type.shortName.${document.reference.type}`) }}
            {{ document.reference.documentNumber }}
          </a>
        </template>
      </Table>
    </div>
    <div class="footer-container">
      <el-button @click="$emit('close')">{{ $t('commons.cancel') }}</el-button>
      <el-button type="primary" :loading="false" @click="handleConfirm"> {{ $t('commons.apply') }}</el-button>
    </div>
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    />
  </el-dialog>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { options } from '@/locale/dateConfig';
import { Table } from '@/modules/core';
import { DocumentModal } from '@/modules/documentModal';
import { useOrderItems } from '@/modules/order';

import { getISODateFromDate } from '@/modules/purchase-management/purchaseManagementFormatters';

const TABLE_HEADERS = {
  DATE: 'date',
  QUANTITY: 'quantity',
  PRICE: 'price',
  DISCOUNT: 'discount',
  REFERENCE: 'reference',
};

export const UPDATE_TYPE = {
  QUANTITY: 'quantity',
  DISCOUNT: 'discount',
  PRICE: 'price',
};

const MAX_ITEMS_TO_SHOW = 5;

export default {
  components: { Table, DocumentModal },
  props: {
    product: { type: Object, required: true },
    value: { type: String, required: true },
    updateType: {
      type: String,
      required: true,
      validator: (value) => Object.values(UPDATE_TYPE).indexOf(value) !== -1,
    },
    activeTab: { type: Number, required: true },
    orderId: { type: String, required: true },
    orderDate: { type: Date, required: true },
    businessId: { type: String, required: true },
  },

  setup(props) {
    const root = getCurrentInstance().proxy;
    const inputRule = computed(() => [
      {
        required: true,
        type: 'number',
        message: root.$i18n.t(`eventMapModal.orderCard.orderUpdateProductModal.rules.${props.updateType}.empty`),
      },
      {
        validator: (__, value, callback) => {
          if (value < 0)
            return callback(
              new Error(
                root.$i18n.t(`eventMapModal.orderCard.orderUpdateProductModal.rules.${props.updateType}.negative`)
              ),
              {}
            );
          callback();
        },
      },
    ]);

    const inputForm = ref({
      newValue: null,
    });

    const variables = computed(() => {
      const toDate = new Date(props.orderDate);
      const fromDate = new Date(toDate);
      fromDate.setFullYear(fromDate.getFullYear() - 1);
      return {
        businessId: props.businessId,
        productId: props.product?.id,
        toDate: getISODateFromDate(toDate),
        fromDate: getISODateFromDate(fromDate),
        limit: MAX_ITEMS_TO_SHOW + 1, // we will filter out the current product showing
        sortBy: 'date',
      };
    });

    const { orderItems, loading } = useOrderItems(variables);

    const formatNumber = (number) => {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(number).toLocaleString(root.$i18n.locale)
        : '';
    };

    return {
      displayedDocument: ref({}),
      orderItems,
      loading,
      inputRule,
      inputForm,
      formatNumber,
    };
  },
  computed: {
    columns() {
      const { DATE, QUANTITY, PRICE, DISCOUNT, REFERENCE } = TABLE_HEADERS;

      return [
        {
          header: this.$t('eventMapModal.orderCard.orderUpdateProductModal.tableHeaders.date'),
          key: DATE,
        },
        {
          header: this.$t('eventMapModal.orderCard.orderUpdateProductModal.tableHeaders.quantity'),
          key: QUANTITY,
        },
        {
          header: this.$t('eventMapModal.orderCard.orderUpdateProductModal.tableHeaders.price'),
          key: PRICE,
        },
        {
          header: this.$t('eventMapModal.orderCard.orderUpdateProductModal.tableHeaders.discount'),
          key: DISCOUNT,
        },
        {
          header: this.$t('eventMapModal.orderCard.orderUpdateProductModal.tableHeaders.reference'),
          key: REFERENCE,
        },
      ];
    },
    tableDataOrderItems() {
      return this.orderItems
        .filter(({ orderId }) => orderId !== this.orderId) // don't show this current order's product
        .map(({ discount, price, quantity, orderDate, orderSource: { document } }) => {
          return {
            [TABLE_HEADERS.DATE]: this.formatDate(orderDate),
            [TABLE_HEADERS.QUANTITY]: quantity,
            [TABLE_HEADERS.PRICE]: price,
            [TABLE_HEADERS.DISCOUNT]: discount ? `${discount}%` : '-',
            [TABLE_HEADERS.REFERENCE]: document,
          };
        });
    },
  },
  methods: {
    handleOpenDocument(documentId) {
      this.displayedDocument = { documentId };
    },
    handleConfirm() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const data = {};
          switch (this.updateType) {
            case UPDATE_TYPE.DISCOUNT:
            case UPDATE_TYPE.PRICE:
              data[this.updateType] = this.inputForm.newValue;
              break;
            default:
              data[this.updateType] = this.activeTab === 1 ? this.inputForm.newValue * -1 : this.inputForm.newValue;
              break;
          }
          this.$emit('update', data);
        }
      });
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style.scss';
@import '@/stylesheets/scss/global';

:deep(.change-modal) {
  width: 592px;
  padding: 0px;
  border-radius: 8px;

  .el-dialog__title {
    font-size: 1.5em;
    color: #000000;
    font-weight: $font-weight-bold;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .el-dialog__header {
    height: 64px;
    padding: 0px 24px;
    border-bottom: 1px solid #e5e8ea;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 40px);
      height: 1px;
      transform: translate(94px, 34px);
    }
  }

  .el-dialog__body {
    padding: 0px;
    margin: 0px;
  }

  .change-modal__input {
    input {
      text-align: center;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }
  .change-modal__info {
    display: grid;
    grid-template-columns: 1fr 18fr;
    gap: 7px;
  }

  .change-modal__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    text-align: center;
    padding: 0px;

    .change-modal__form__current-value-title {
      margin-bottom: 7px;
    }
    .change-modal__form__current-value-text {
      font-size: 1.2em;
    }
  }
}

.general-info-container {
  padding: 24px;
  color: #000000;
}

.general-info-container__sku {
  font-size: 12px;
}

.card {
  margin: 0px 24px;
  height: 154px;
  display: flex;
  flex-direction: row;
}

.card-body {
  display: flex;
  align-items: center;
  margin-top: 45px;
  flex-basis: 50%;
  flex-direction: column;
  padding: 0px;
  color: #000000;
}

.card-body__value__container {
  height: 40px;
  display: flex;
  align-items: center;
}

.card-body__second__child {
  margin-top: 14px;
}

.table-title-container {
  margin-top: 24px;
  color: #000000;
  padding: 0px 24px;
}

.table-container {
  margin-top: 16px;
  width: 100%;
  padding: 0px 24px 25px 24px;
  border-bottom: 1px solid #e5e8ea;
}

.footer-container {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
}
</style>
